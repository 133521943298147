import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonCard = (props) => {
    return (
      <div className="component-grid-list">
          {Array(parseInt(props.nbItems))
            .fill()
            .map((item, index) => (
              <div className="component-grid-element" key={index}>
                <Skeleton className="component-skeleton-element-img" />
                <Skeleton className="component-grid-element-title component-skeleton-element-title" />
              </div>
            ))}
        </div>
    );
  };

  export default SkeletonCard;
