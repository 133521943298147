import React, { PureComponent } from "react";
import APIUtilities from './APIUtilities.js'

const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
//const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

export default class Calendar extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      firstLoad : true,
      date : new Date()
    };

    this.clickOnPreviousDay = this.clickOnPreviousDay.bind(this);
    this.clickOnNextDay = this.clickOnNextDay.bind(this);
  };

  async getTimeSlots(date) {
    const slots = await APIUtilities.getTimeSlots(date);

    this.setState({
      firstLoad : false,
      date: date,
      slotList : slots
    });
  }

  clickOnPreviousDay() {
    var date = new Date(this.state.date);
    const today = new Date();

    if(date < today)
      return;

    date.setDate(date.getDate() - 2);
    this.getTimeSlots(date);
  }

  clickOnNextDay() {
    var date = new Date(this.state.date);
    date.setDate(date.getDate() + 2);
    this.getTimeSlots(date);
  }

  componentDidMount() {
    this.getTimeSlots(this.state.date);
  }

  printDay(date) {
    var correctDate = new Date(date);
    //var stringDate = days[correctDate.getDay()] + ' ' + correctDate.getDate() + ' ' + months[correctDate.getMonth()];
    var month = correctDate.getMonth()+1+"";
    month = month.length > 1 ? month : "0" + month;

    var stringDate = days[correctDate.getDay()] + ' ' + correctDate.getDate() + '/' + month;
    return stringDate;
  }

  render() {
    if(this.state.firstLoad)
      return null;
    else
    return (
      <>
      <button className="switch-days-button" id="previous-days" onClick={this.clickOnPreviousDay}>-</button>
      <div className="appointment-grid">
        {this.state.slotList.periode_LIST.map((day, index) => (
          <div className="appointment-element" key={index} id={"appointment-element-"+index}>
            <div className="appointment-element-title">
              {this.printDay(day.date)}
            </div>
            {day.periode_LIST.map(slot => (
              <label>
                <input type="radio" className="checkbox-appointment-element-slot" name="slot-choice"/>
                <div className="appointment-element-slot">
                  {slot.HeureDebut}h{slot.MinuteDebut} à {slot.HeureFin}h{slot.MinuteFin}
                </div>
              </label>
            ))}
          </div>
        ))}
      </div>
      <button className="switch-days-button" id="next-days" onClick={this.clickOnNextDay}>+</button>
    </>);
  }
}
